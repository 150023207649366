import { useState } from "react";
import Header from "../../components/Header";
import { leekmap, leekmapDescription, leekmapIds } from "../../constants";
import styles from "./leekmap.module.scss";
import cn from "classnames";
import Footer from "../../components/Footer";

const Leekmap = () => {
  const [leekmapId, setLeekmapId] = useState<string>(leekmapIds.first);

  return (
    <>
      <div id="leekmap" className={styles["leekmap"]}>
        <Header />
        <div className="container">
          <h2>Leekmap</h2>
          <div className={styles["leekmap__wrap"]}>
            {leekmap.map((item) => (
              <button
                onClick={() => setLeekmapId(item.id)}
                className={cn(styles["leekmap__btn"], {
                  [styles["leekmap__btn_active"]]: leekmapId === item.id,
                })}
                key={item.id}
              >
                <div className={styles["leekmap__id"]}>{item.id}</div>
                <div className={styles["leekmap__info"]}>
                  <h3 className={styles["leekmap__title"]}>{item.name}</h3>
                  <img src={item.img} alt={item.name} />
                </div>
              </button>
            ))}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: leekmapDescription[leekmapId] }}
            className={styles["leekmap__description"]}
          ></div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Leekmap;
