import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./stake.module.scss";

const Stake = () => {
  return (
    <>
      <div id="stake" className={styles["stake"]}>
        <Header />
        <div className="container">
          <h2>Staking</h2>
          <button className="btn">coming soon</button>
        </div>
        <div className={styles['footer']}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Stake;
