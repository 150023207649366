import cn from "classnames";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Discord, logo, Twitter } from "../../assets/images";
import { menu, PATHS } from "../../constants";
import styles from "./index.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  const handleToogleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <header
        className={cn(styles.header)}
      >
        <div className="container">
          <div className={styles["header__wrap"]}>
            <div>
              <Link to={"/"}>
                <img className={styles.header__logo} src={logo} alt="Logo" />
              </Link>
            </div>
            <div>
              <nav>
                <ul
                  className={cn(styles.menu, {
                    [styles.menu__open]: isOpen,
                  })}
                >
                  {menu.map((item, idx) => (
                    <li
                      className={cn(styles["menu__item"], {
                        [styles.menu__item_home]: pathname === PATHS.home,
                        [styles.menu__item_active]: item.path === pathname,
                      })}
                      key={idx}
                    >
                      <Link className={styles["menu__link"]} to={item.path}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className={styles["header__info"]}>
              <button onClick={handleToogleMenu} className={styles.burger__btn}>
                <div
                  className={cn(styles.burger__icon, {
                    [styles.burger__icon_open]: isOpen,
                  })}
                />
              </button>
              <div className={cn(styles["social"])}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/metaleeks"
                >
                  <Discord />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/MetaLeeksNFT"
                >
                  <Twitter />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
