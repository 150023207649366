import { Twitter } from "@material-ui/icons";
import { Discord, join } from "../../assets/images";
import Collapse from "../../components/Collapse";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { faq } from "../../constants";
import styles from "./faq.module.scss";

const Faq = () => {
  return (
    <>
      <div id="faq" className={styles["faq"]}>
        <Header />
        <div className="container">
          <h2>faq</h2>
          <div className={styles["faq__wrap"]}>
            {faq.map((item) => (
              <Collapse
                id={item.id}
                title={item.title}
                key={item.id}
                idx={item.id}
              >
                {item.text}
              </Collapse>
            ))}
          </div>
          <div className={styles["join"]}>
            <img className={styles['join__img']} src={join} alt="Join" />
            <div className={styles['join__info']}>
              <h2>join our community!</h2>
              <div className={styles['join__btns']}>
              <a className="btn" rel="noreferrer" target="_blank" href="https://discord.gg/metaleeks">
                <Discord /> join our discord
              </a>
              <a className="btn" rel="noreferrer" target="_blank" href="https://twitter.com/MetaLeeksNFT">
                <Twitter /> follow us on twitter
              </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Faq;
