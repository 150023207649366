import {
  leekmap1,
  leekmap2,
  leekmap3,
  leekmap4,
  team1,
  team2,
  team3,
  team4,
  team5
} from "./assets/images";

export const PATHS = {
  home: "/",
  leekmap: "/leekmap",
  story: "/story",
  team: "/team",
  faq: "/faq",
  stake: "/stake",
};

export const menu = [
  { id: 1, name: "story", path: "/story" },
  { id: 2, name: "leekmap", path: "/leekmap" },
  { id: 3, name: "team", path: "/team" },
  { id: 4, name: "faq", path: "/faq" },
  { id: 5, name: "stake", path: "/stake" },
];

export const faq = [
  {
    id: 2,
    title: "How large is the collection ?",
    text: `
    <p>MetaLeeks will rise on the Solana Blockchain with a collection of 5555 unique NFTs</p>
  `,
  },
  {
    id: 4,
    title: "How many can I mint per wallet ?",
    text: `
    <p>  Mint allocation is as follows :</p> 
    <p> - OG allocation : 2 per wallet   </p> 
    <p> - WL allocation : 3 per wallet </p>
    <p> - Public allocation : unlimited </p>
  `,
  },
  {
    id: 6,
    title: "How do I get Whitelist ?",
    text: `
    <p>Please refer to our Discord. (link below)</p>
    <p><a href="https://discord.gg/metaleeks" target="_blank">https://discord.gg/metaleeks</a></p>
  `,
  },
];

export const team = [
  { id: 1, twitter: "@Jackytellem", position: "Co-Founder", img: team1 },
  { id: 2, twitter: "@CozMatrix", position: "Co-Founder", img: team2 },
  { id: 3, twitter: "@HxnterFN", position: "Community Manager", img: team3 },
  { id: 4, twitter: "@Lea", position: "Artist", img: team4 },
  { id: 5, twitter: "@Jeff", position: "Dev", img: team5 }, 
];

export const leekmapIds = {
  first: "01",
  second: "02",
  third: "03",
  fourth: "04",
};

export const leekmap = [
  { id: "01", name: "Long and short of it", img: leekmap1 },
  { id: "02", name: "Value", img: leekmap2 },
  { id: "03", name: "Legacy", img: leekmap3 },
  { id: "04", name: "Dexterity", img: leekmap4 },
];

export const leekmapDescription: any = {
  "01": `
  <p>MetaLeeks Club, is a closed circle gathering a passionate private community of investors, entrepreneurs and Web3.0 enthusiasts, newbies as well as amateurs or professionals, whose members will always be ahead of the rest of civilization. Why?
  </p>
  <p>Based on the value of sharing, the MLC community is a so-called "Builders" community. The entrepreneurial mindset is omnipresent, the "we" is superior to the "me". At MLC, we truly believe that together we can do more than if we do it individually. From builders, creators and influencers, to people who want to set up a career, network and change Web3.0 for the better, MLC is the right springboard.
  </p>
`,
  "02": `
  <h4>IRL events/conference</h4>
  <p>MetaLeeks team will host IRL events for both Web3.0 enthusiasts and entrepreneurs, with guests and influencers from all over the world. Every month, holders and the most active, value-adding members will be selected to have premium access to these venues. That doesn’t mean that the others' access will be compromised.</p>
  <h4>Partnerships</h4>
  <p>MetaLeeks team will be partnering with renowned incubation and acceleration programs in the VC world as well as with event pioneers in the Web3.0 and startup spaces to provide all MetaLeeks holders with passes to all the most coveted events.</p>
  <h4>Periodic Airdrop</h4>
  <p>Holders will randomly receive an airdrop of $LEEK OR high utility NFT on a periodic basis (To Be Announced).</p>`,
  "03": `
  <h4>WEB 3.0 Librarium</h4>
  <p>The MetaLeeks team will deploy a Web3.0 librarium in which holders will be able to trade the knowledge THEY OWN on a variety of topics in a variety of forms via our ecosystem’s token $LEEK.</p>
  <h4>Staking</h4>
  <p>Holders will be able to stake and generate tokens using our ecosystem's token, $LEEK.</p>
  <h4>Raffles & auctions</h4>
  <p>The 5555 MetaLeeks have been minted by our Holders. Directly the profits from the mint are secured for the development of the project.
  30% of the profits are placed in the community wallet and another 40% are placed in the treasury.
  These funds will be used exclusively by our team to develop the project (Dev, marketing, roadmap key steps) and to implement the community’s proposals.
  </p>`,
  "04": `
  <h4>Leeks Royalties</h4>
  <p>LEEKS support builders and innovators. Every day, the MetaLeek community demonstrates exceptional leadership and there is no better way to support those who contribute to the development of their vision. MetaLeeks team will be developing a royalty system for the outstanding creators in the community with great insights.</p>
  `,
};
