import { storyBg } from "../../assets/images";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./story.module.scss";

const StoryPage = () => {
  return (
    <div id="story" className={styles["story"]}>
      <Header />
      <div className="container">
        <h2>Story</h2>
        <div className={styles["story__wrap"]}>
          <div>
            <p>
              In a world parallel to ours, everything seems identical on the
              surface. The economy has never been so strong, prices and interest
              rates have never been so excessive and societies have never been
              so consuming. But without realizing it, the leeks spend their
              lives studying and working, blinded by the codes of society to the
              point of drowning in houses, cars and other ostentatious objects
              that drag them even further into the maelstrom of debt. They are
              constantly competing with others for the coveted degrees and high
              positions, until they forget their passion and their real goal in
              life.
            </p>
            <p>
              But a small community of leeks is fed up with the status quo, fed
              up with the dependency and lack of legacy in this world. They are
              made up of leeks of all ages and backgrounds. Each with different
              backgrounds and professions Doctors, lawyers, military, students,
              and anyone who can see through this veil of unfulfilled dreams can
              know the reason for this repression.
            </p>
            <p>
              So they call themselves "entreprerleeks", they gather and use
              their collective strength to transport themselves into the world
              of WEB3.0. There they find the tools and resources they need to
              initiate change in their world. With determination and courage,
              they begin to transform their society into a more just and
              equitable one for all while building a legacy for the next
              generations.
            </p>
            <p>This is the birth of the MetaLeeks.</p>
          </div>
          <img className={styles['story__img']} src={storyBg} alt="Story" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StoryPage;
