import { Twitter } from "../../assets/images";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { team } from "../../constants";
import styles from "./team.module.scss";

const Team = () => {
  return (
    <>
      <div id="team" className={styles["team"]}>
        <Header />
        <div className="container">
          <h2>The Team</h2>
          <div className={styles["team__wrap"]}>
            {team.map((item) => (
              <div className={styles["block"]} key={item.id}>
                <img src={item.img} alt={item.twitter} />
                {item.id !== team.length && item.id !== team.length - 1 ?
                  <a rel="noreferrer" target="_blank" href={`https://twitter.com/${item.twitter}`}>
                    <div className={styles["block__title"]}>
                      <h3>{item.twitter}</h3>
                      <div className={styles["block__icon"]}>
                        <Twitter />
                      </div>
                    </div>
                  </a>
                  :
                  <div className={styles["block__title"]}>
                    <h3>{item.twitter}</h3>
                  </div>
                }
                <p className={styles["block__position"]}>{item.position}</p>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Team;
