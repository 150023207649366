import Header from "../../components/Header";
import styles from "./home.module.scss";
import { Arrow, title } from "../../assets/images";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";

const HomePage = () => {
  return (
    <>
      <div id="intro" className={styles["home"]}>
        <Header />
        <div className="container">
          <div className={styles["home__title"]}>
            <img src={title} alt="Metaleeks Club" />
          </div>
          <div className={styles["meet"]}>
            <Link className={styles["meet__btn"]} to={PATHS.team}>
              Meet our Team
            </Link>
           <div className={styles['meet__arrow']}><Arrow /></div> 
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
