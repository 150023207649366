import styles from "./index.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <footer className={styles["footer"]}>
      <div className="container">
        <p>© {date.getFullYear()}, MetaLeeks Club</p>
      </div>
    </footer>
  );
};

export default Footer;
